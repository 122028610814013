<template>
	<div class="ele-body">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="通话套餐设置" name="first">
				<el-card shadow="never">
					<el-form ref="form" :model="sizeform" label-width="120px">
						<div class="innerBox">
							<div class="innerTitle">语音套餐一：</div>
							<el-form-item label="分钟(单位:分)：">
								<el-input v-model="sizeform.num1" class="inputWidth" clearable></el-input>
							</el-form-item>
							<el-form-item label="金额(元)：">
								<el-input v-model="sizeform.price1" class="inputWidth" clearable></el-input>
							</el-form-item>

						</div>
						<div class="innerBox">
							<div class="innerTitle">语音套餐二：</div>
							<el-form-item label="分钟(单位:分)：">
								<el-input v-model="sizeform.num2" class="inputWidth" clearable></el-input>
							</el-form-item>
							<el-form-item label="金额(元)：">
								<el-input v-model="sizeform.price2" class="inputWidth" clearable></el-input>
							</el-form-item>
						</div>
						<div class="innerBox">
							<div class="innerTitle">语音套餐三：</div>
							<el-form-item label="分钟(单位:分)：">
								<el-input v-model="sizeform.num3" class="inputWidth" clearable></el-input>
							</el-form-item>
							<el-form-item label="金额(元)：">
								<el-input v-model="sizeform.price3" class="inputWidth" clearable></el-input>
							</el-form-item>
						</div>
						<div class="innerBoxTo">
							<el-form-item style="float:right;">
								<el-button type="primary" @click="save">确认提交</el-button>
							</el-form-item>
						</div>
					</el-form>
				</el-card>
			</el-tab-pane>

		</el-tabs>
    <!--购买短信套餐-->
    <el-dialog v-dialogDrag title="购买语音套餐" :visible.sync="dialogVisiblePlans" custom-class="ele-dialog-form"
               :lock-scroll="false" class="plans" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="true">
      <el-card shadow="never">
        <div class="plansBox">
          <div class="plansItem" style="width: 25%;float: left;">
            <div class="plansInner " :class="active == 1? 'greenborder':''" @click="handleChange(1,editFive.num1,editFive.price1)" >
              <div class="plansTitle">{{editFive.num1}}分钟语音套餐</div>
              <div class="plansMoney">￥{{editFive.price1}}</div>
              <div class="plansCluse">包含：{{editFive.num1}}分钟</div>
            </div>

          </div>
          <div class="plansItem" style="width: 25%;float: left;">
            <div class="plansInner " :class="active == 2? 'greenborder':''" @click="handleChange(2,editFive.num2,editFive.price2)" >
              <div class="plansTitle">{{editFive.num2}}分钟语音套餐</div>
              <div class="plansMoney">￥{{editFive.price2}}</div>
              <div class="plansCluse">包含：{{editFive.num2}}分钟</div>
            </div>

          </div>
          <div class="plansItem" style="width: 25%;float: left;">
            <div class="plansInner " :class="active == 3? 'greenborder':''" @click="handleChange(3,editFive.num3,editFive.price3)" >
              <div class="plansTitle">{{editFive.num3}}分钟语音套餐</div>
              <div class="plansMoney">￥{{editFive.price3}}</div>
              <div class="plansCluse">包含：{{editFive.num3}}分钟</div>
            </div>

          </div>
          <div class="plansDeduct">支付方式： <span>账户余额扣除</span></div>
          <div class="plansDeduct">支付金额（元）： 
						<span>￥{{price}}</span>
						<!-- <span v-if="price == 0">￥{{editFive.pay_price}}</span>
						<span v-else>￥{{price}}</span> -->
					</div>
        </div>

      </el-card>
      <div slot="footer">
        <!-- <template slot-scope="{option}"> -->
        <!-- <el-button @click="dialogVisiblePlans=false">取消</el-button> -->
        <el-button type="primary" @click="purchaseSave(num,price,editFive.id)">确认购买</el-button>
				<a style="" @click="dialogVisiblePlans=false" class="closeBtn">关闭</a>
        <!-- </template> -->

      </div>
    </el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "SysUser",

		data() {
			return {
				currIndex: 0,
				zoom: 16,
				center: [114.397169, 30.50576],
				charList: [],
				open: {},
				table: {
					url: '/voice/voiceRecharge',
					where: {}
				}, // 表格配置
				table1: {
					url: '/voice/consume',
					where: {}
				}, // 语音消费列表表格配置
				table2: {
					url: '/voice/voiceSwitch',
					where: {}
				}, // 语音开关表格配置
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				activeName: 'first',
				dialogVisibleAdd: false,
				dialogVisibleOpen: false,
				dialogFormVisibleView: false,

				sizeform: {},
				voiceRecharge: {},
				aa: true,
				ampDisplay: false,
				ampSrc: '',
				audioArr: [],
				driverInfo: {},
				orderInfo: {},
				audio: '',
        dialogVisiblePlans:false,
        editFive:{},
				active:1,
				price:0,
				num:0
			}
		},
		created() {
			this.init()

		},

		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {
			// 初始化 接口
			init(){
				this.$http.get('/common/province_list').then(res => {
					let data = JSON.parse(res.data)
					this.provArr = data
				})

				this.$http.get('/voice/voiceModel').then(res => {
					let data = res.data
					this.sizeform = data.data
				})
				this.$http.get('/voice/voiceRecharge').then(res => {
					this.voiceRecharge = res.data
				})
			},
			form() {},
			// 开启
			handleopen() {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/voice/voiceOpen', this.open).then(res => {
					loading.close();
					let data = res.data
					if (data.code === 1) {
						this.$message({
							type: 'success',
							message: '开启成功'
						});
						this.$refs.table2.reload();
					} else {
						this.$message.error(data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			},
			handleChat(row) {
				//console.log(row)
				this.dialogVisibleAdd = true
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".records")
				});
				// this.$http.post('/order/chat_log',{id:row.id}).then(res=>{
				this.$http.post('/order/chat_log', {
					id: row.order_id 
				}).then(res => {
					var data = res.data
					loading.close()
					data.log.forEach(function(item) {
						item.isTimeShow = 1
						if (item.content.S == 21) {
							item.isPlay = true
							if (item.content.P.LONG == 1) {
								item.bgWidth = '8rem'
							}
							item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
						}
						if (item.content.S == 26) {
							item.content.P.POINT = item.content.P.POINT.split(',')
							//console.log(item.content.P.POINT)

						}
					});
					for (var i = 0; i < data.log.length; i++) {
						for (var j = i + 1; j < data.log.length - 1; j++) {
							if (data.log[i].create_time == data.log[j].create_time) {
								data.log[j].isTimeShow = 0 //不显示时间
							} else {
								data.log[j].isTimeShow = 1
							}
						}
					}
					this.charList = data.log

					this.driverInfo = data.driver
					this.orderInfo = data.order_info
				})
			},
			hanlePicture(src) {
				this.ampDisplay = true
				this.ampSrc = src
			},
			handleClose() {
				this.ampDisplay = false
			},
			handlePlay() {
				let that = this
				let audio = new Audio('https://zszx.zdache.cn:8441/dingdong.aac');
				audio.play();
				this.aa = false
				audio.addEventListener('ended', function() {
					that.aa = true
				}, false);
			},
			handleChange(active,num,price) {
				this.active = active
				this.price = price
				this.num = num
				// this.editFive.forEach(function(item) {
				// 	if (item.id == id) {
				// 		item.greenborder = true
				// 	} else {
				// 		item.greenborder = false
				// 	}
				// })
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				//console.log(e)
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.open.cid = ''
					this.open.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.open.aid = ''
				})
				}else{
					this.open.pid = ''
					this.open.aid = ''
				}
			},
			/**
			 *选择省
			 **/
			handleChangeProv2(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table2.where.cid = ''
					this.table2.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity2(e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table2.where.aid = ''
				})
			},
			save() {
				const loading = this.$loading({
					lock: true
				});
				let sizeform = this.sizeform
				let param = {
					num1: sizeform.num1,
					price1: sizeform.price1,
					num2: sizeform.num2,
					price2: sizeform.price2,
					num3: sizeform.num3,
					price3: sizeform.price3
				}
				this.$http.post('/voice/voiceTempData', param).then(res => {
					loading.close();
					let data = res.data
					if (data.code == 1) {
						this.$message({
							message: data.msg,
							type: 'success'
						})
					} else {
						this.$message.error(data.msg)
					}
				}).catch(e => {
					this.$message.error(e.message);

				})
			},
			closeDialog() {
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (var i = 0; i < middlePlay.length; i++) {
					middlePlay[i].pause()
					middlePlay[i].currentTime = 0
				}
			},
			handleClick() {},
			switch_start(row) {
				if (row.type == 1) {
					this.$confirm('确定关闭语音通话吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/voice/openingType', {
							id: row.id,
							type: 2
						}).then(res => {
							loading.close();
							let data = res.data
							if (data.code === 1) {

								this.$message({
									type: 'success',
									message: '关闭成功'
								});
								this.$refs.table2.reload();
							} else {
								this.$message.error(data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				} else {
					this.$confirm('确定启用语音通话吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});

						this.$http.post('/voice/openingType', {
							id: row.id,
							type: 1
						}).then(res => {
							let data = res.data
							loading.close();
							if (data.code === 1) {
								this.$message({
									type: 'success',
									message: '启用成功'
								});
								this.$refs.table2.reload();
								this.switchStart = '关闭'
							} else {
								this.$message.error(data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},

      // 购买短信套餐
      purchaseSave(num,price,id) {
        const loading = this.$loading({ lock: true,target: document.querySelector(".plans")});
        this.$http.post('/Voice/voice_buy', {
			num: num,
			price: price,
			id: this.voiceRecharge.admin_id
        }).then(res => {
          if (res.data.code === 0) {
            // const loading = this.$loading({ lock: true,target: document.querySelector(".d-flexspabet")});
            this.dialogVisiblePlans = false;
            this.$message({
              type: 'success',
              message: '购买成功'
            });
            this.init()
			this.$refs.table.reload()
          } else {
            this.$message.error({
              type: 'error',
              message: '购买失败'
            });
          }
        })
      },

		// 短信套餐展示框
      handlePurchase() {
        this.dialogVisiblePlans = true
        const loading = this.$loading({
          lock: true,
          target: document.querySelector(".plans")
        });
        this.$http.get('/Voice/voiceModel', ).then(res => {
          let data = res.data

          loading.close()
          // data.forEach(function(item) {
          //   if (item.id == 1) {
          //     item.greenborder = true
          //   } else {
          //     item.greenborder = false
          //   }
          //
          // })
          this.editFive = data.data
          this.editFive.pay_price = data.data.price1
					this.price = this.editFive.price1
					this.num = this.editFive.num1
          //console.log(data.data);
        })
      },


		}
	}
</script>
<style>
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	.el-form-item-label {
		width: 20%;
		text-align: left
	}


	.mr-10 {
		margin-right: 10px;
	}

	.el-date-editor /deep/.el-input,
	.el-date-editor /deep/.el-input__inner {
		width: 70% !important;
	}
</style>

<style scoped>
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.ele-body {
		padding: 15px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	/deep/.el-tabs__item {
		padding: 0 20px !important;
		font-size: 15px;
	}

	/deep/.el-tabs__nav-scroll {
		background: white !important;
		padding-top: 10px;
	}

	.boxTitle {
		padding: 8px 0
	}

	.orderImgBox {
		margin-left: 10px;
		height: 80px;
	}

	.orderImgBox .orderImgBoxText {
		font-size: 36px;
		padding-left: 20px
	}

	.user-info-tabs>>>.el-tabs__nav-wrap {
		padding-left: 20px;
	}

	.innerBox {
		width: 40%;
		margin-left: 100px;
		padding-top: 20px;
		border-bottom: 1px rgb(240, 240, 240) solid;
	}

	.innerBoxTo {
		width: 40%;
		margin-left: 100px;
		padding-top: 20px;
	}

	.innerTitle {
		font-size: 16px;
		margin-bottom: 20px;
		margin-left: 13px;
	}

	.inputWidth {
		width: 100%;
	}
	/deep/.el-dialog{
		margin-top: 60px !important;
	}


  .plansBox {
    width: 100%;
    border: 1px solid rgb(240, 240, 240);
    margin: auto
  }

  .plansItem {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: 0 30px;
  }

  .plansInner {
    padding: 0 10px;
    border: 1px solid rgb(240, 240, 240);
    margin: 20px 0;
    width: 100%
  }

  .plansInner1 {
    margin: 20px 0;
    width: 18%
  }

  .plansCon {
    padding: 0 10px 10px;
    border: 1px solid rgb(240, 240, 240);
    width: 100%;
    margin-bottom: 20px;
    height: 310px
  }

  .plansTitle {
    color: #33cc99;
    padding: 30px 0 20px 0;
  }

  .plansMoney {
    color: #ff892e;
    padding: 0 20px 20px;
    border-bottom: 1px solid rgb(240, 240, 240);
    width: 40%;
    margin: auto;
  }

  .plansCluse {
    padding: 20px;
  }

  .plansNum {
    padding-bottom: 40px;
  }

  .plansMiaoshu {
    padding: 60px 0 0;
    color: #33cc99
  }

  .plansDeduct {
    padding-left: 50px;
    padding-bottom: 20px;
    float: left;
    width: 100%;
  }

  .plansDeduct span {
    color: #ff892e;
    padding-left: 30px
  }

  .plansContent {
    padding: 30px 0;
    height: 120px
  }
	.greenborder {
		border: 1px solid #33cc99 !important
	}
	.closeBtn{
		cursor: pointer;
		display: inline-block;height: 36px;width: 90px;text-align: center;line-height: 36px;margin-left: 10px;
		background: red;border-radius: 4px;font-size: 14px;color: white;text-decoration: none;
	}
</style>
